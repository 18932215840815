import { currencySek } from '@/store/modules/common'
import _round from 'lodash/round'

export default {
  formatAmount (amount, currency) {
    let result = ''
    if ((amount === 0 || amount) && isFinite(amount)) {
      amount = this.roundAmount(amount, 2)
      if (currency) {
        result = amount.toLocaleString({ style: 'currency', currency: currency })
      } else {
        result = amount
      }
    }
    if (currency) {
      result += ' ' + currency
    }

    return result
  },
  formatAmountMain (amount) {
    return this.formatAmount(amount, currencySek)
  },
  roundAmount (number) {
    return this.roundNumber(number, 2)
  },

  roundNumber (number, decimalSigns) {
    return _round(number, decimalSigns)
  },

  formatInteger (value) {
    if (!value) {
      return ''
    }
    return value.toFixed(0)
  },

  formatSize (sizeInKb) {
    if (!sizeInKb) {
      return ''
    }

    let size = sizeInKb
    if (size < 1024) {
      return size.toFixed(2) + ' Kb'
    }

    size = size / 1024
    if (size < 1024) {
      return size.toFixed(2) + ' Mb'
    }

    size = size / 1024
    return size.toFixed(2) + ' Gb'
  }
}
