import 'izitoast/dist/css/iziToast.min.css'
import iZtoast from 'izitoast'
import Vue from 'vue'
import i18n from '@/plugins/i18n'

const toast = {
  error: (message, title = 'Error') => {
    return iZtoast.error({
      title: title,
      message: message,
      position: 'topCenter'
    })
  },
  success: (message, title = 'Success') => {
    return iZtoast.success({
      title: title,
      message: message,
      position: 'topCenter',
      color: '#557f01',
      theme: 'dark'
    })
  },
  warning: (message, title = 'Warning') => {
    return iZtoast.warning({
      title: title,
      message: message,
      position: 'topCenter'
    })
  },
  info: (message, title = 'Info') => {
    return iZtoast.info({
      title: title,
      message: message,
      position: 'topCenter'
    })
  },
  infobox: (title, message) => {
    return iZtoast.info({
      color: '#557f01B0',
      timeout: false,
      overlay: true,
      displayMode: 'once',
      title: title,
      message: message,
      position: 'center',
      drag: false,
      layout: 2,
      theme: 'dark',
      icon: false
    })
  },
  confirm: (message, okBtn, cancelBtn) => {
    return new Promise((resolve, reject) => {
      Vue.dialog.confirm(message, {
        okText: okBtn || i18n.t('common.button.confirm'),
        cancelText: cancelBtn || i18n.t('common.button.cancel')
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}

export default toast
