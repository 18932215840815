import api from '@/js/services/ajax'
import ImageRepository from '@/js/api/ImageRepository'
import OtherRepository from '@/js/api/OtherRepository'

export default {

  getAll () {
    return new Promise((resolve, reject) => {
      api.get('v2/invoices/?extended=true')
        .then(response => {
          resolve(this._prepareInvoices(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getByUuid (uuid) {
    return new Promise((resolve, reject) => {
      api.get('v2/invoices/' + uuid + '?extended=true')
        .then(response => {
          resolve(this._prepareInvoice(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getItemsByUuid (uuid) {
    return new Promise((resolve, reject) => {
      api.get('v2/invoices/' + uuid + '/items?extended=true')
        .then(response => {
          resolve(this._prepareItems(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getItemDownloadUrl (uuid, id, downloadOriginalFormat) {
    return new Promise((resolve, reject) => {
      api.post('v2/invoices/' + uuid + '/items/' + id + '/download?originalformat=' + encodeURIComponent(downloadOriginalFormat))
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPurchasedItems () {
    return new Promise((resolve, reject) => {
      api.get('v2/purched-items?extended=true')
        .then(response => {
          resolve(this._prepareItems(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareInvoices (itemArray) {
    var resultArray = []
    if (!itemArray) {
      return resultArray
    }

    itemArray.forEach((item) => {
      resultArray = resultArray.concat(this._prepareInvoice(item))
    })
    return resultArray
  },

  _prepareInvoice (obj) {
    if (!obj) {
      return null
    }

    obj.items = this._prepareItems(obj.items)
    return obj
  },

  _prepareItems (itemArray) {
    var resultArray = []
    if (!itemArray) {
      return resultArray
    }

    itemArray.forEach((item) => {
      resultArray = resultArray.concat(this._prepareItem(item))
    })
    return resultArray
  },

  _prepareItem (item) {
    if (!item) {
      return null
    }

    if (item.image) {
      item.image = ImageRepository._prepareImage(item.image)
    }
    if (item.category) {
      item.category = OtherRepository._prepareCategory(item.category)
    }
    return item
  }
}
