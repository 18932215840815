import api from '@/js/services/ajax'
import ImageRepository from '@/js/api/ImageRepository'

export default {

  getAll () {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.sort_column = null
      payload.sort_type = 'desc'
      payload.limit = 1000
      payload.offset = 0

      api.get('v2/favorites/?extended=true', payload)
        .then(response => {
          resolve(this._prepareFavorites(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addFavorite (imageId) {
    return new Promise((resolve, reject) => {
      api.post('favorite/' + imageId + '/1')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeFavorite (imageId) {
    return new Promise((resolve, reject) => {
      api.post('favorite/' + imageId + '/0')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareFavorites (itemArray) {
    var resultArray = []
    if (!itemArray) {
      return resultArray
    }

    itemArray.forEach((item) => {
      resultArray = resultArray.concat(this._prepareFavorite(item))
    })
    return resultArray
  },

  _prepareFavorite (obj) {
    if (!obj) {
      return null
    }

    if (obj.image) {
      obj.image = ImageRepository._prepareImage(obj.image)
    }
    return obj
  }
}
