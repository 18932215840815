import PriceRepository from '@/js/api/PriceRepository'
import OtherRepository from '@/js/api/OtherRepository'
import PostRepository from '@/js/api/PostRepository'
import objectUtil from '@/js/utils/objectUtil'
import store from '@/store/store'
import locales from '@/locales/locales'

const localeSv = locales.getLocaleByCode('sv')
const localeEn = locales.getLocaleByCode('en')
export const currencySek = localeSv.currency
export const currencyEur = localeEn.currency
export const languageSe = localeSv.code
export const languageEn = localeEn.code
export const lastVersion = 16
const defaultCurrencyList = [{ value: currencySek, text: 'SEK' }, { value: currencyEur, text: 'EUR' }]
const defaultLanguageList = [{ value: languageSe, text: localeSv.name }, { value: languageEn, text: localeEn.name }]
const defaultPageNavList = [
  { text: 'services', link_trl: 'header.navigation.services-link' },
  { text: 'buy-images', link: '/buy-images' },
  { text: 'sell-images', link: '/sell-images' },
  { text: 'text-to-media', link: '/text-to-media' },
  { text: 'about', link: '/about-us' }]

const getDefaultState = () => {
  return {
    currencies: defaultCurrencyList,
    currency: defaultCurrencyList[0],
    prices: [],
    categories: [],
    languages: defaultLanguageList,
    version: lastVersion,
    pageNavigation: defaultPageNavList,
    collectionMap: [],
    lastTimeLoaded: null,
    siteConfig: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },

    setPrices (state, prices) {
      state.prices = prices
    },

    setCurrency (state, currency) {
      state.currency = currency
    },

    setCategories (state, categories) {
      state.categories = categories
    },

    setCurrencyList (state, currencies) {
      state.currencies = currencies
    },

    setLanguageList (state, languages) {
      state.languages = languages
    },

    setPageNavigationList (state, pageNavigation) {
      state.pageNavigation = pageNavigation
    },

    setCollections (state, collectoins) {
      const collectionMap = collectoins.map(collection => { return { page: collection.collection_page, id: collection.id } })
      state.collectionMap = objectUtil.clone(collectionMap)
    },
    setLastTimeLoaded (state, date) {
      state.lastTimeLoaded = date
    },
    setSiteConfig (state, siteConfig) {
      state.siteConfig = siteConfig
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    loadPrices ({ commit, getters }) {
      const currencyVal = getters.currency.value
      PriceRepository.find(currencyVal)
        .then((prices) => {
          commit('setPrices', prices)
        })
    },

    loadCategories ({ commit }) {
      OtherRepository.findCategories()
        .then((categories) => {
          commit('setCategories', categories)
        })
    },

    loadCollections ({ commit }) {
      return new Promise((resolve, reject) => {
        PostRepository.find('collection')
          .then((collections) => {
            commit('setCollections', collections)
            resolve(collections)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    loadSiteConfig ({ commit }) {
      OtherRepository.getSiteConfig()
        .then((siteConfig) => {
          commit('setSiteConfig', siteConfig)
        })
    },

    setCurrency ({ commit, dispatch, getters }, currencyCode) {
      const currency = getters.getCurrencyByCode(currencyCode)
      commit('setCurrency', currency)

      // On currenct change we need to reload prices, categories and cart
      dispatch('reloadDefaultLists', true)
    },

    reloadDefaultLists ({ commit, dispatch, getters }, force) {
      const currentDate = new Date()
      if (force === false) {
        const currentCurrency = getters.currency
        const prices = getters.prices
        const categories = getters.categories

        let lastTimeLoaded = getters.lastTimeLoaded
        if (lastTimeLoaded) {
          lastTimeLoaded = new Date(lastTimeLoaded)
          lastTimeLoaded.setDate(lastTimeLoaded.getDate() + 1)
        }

        // Reload at least once a day
        if (lastTimeLoaded && lastTimeLoaded > currentDate &&
          prices && prices.length > 0 &&
          categories && categories.length > 0 &&
          currentCurrency && categories[0].currency === currentCurrency.value) {
          return
        }
      }

      dispatch('loadSiteConfig')
      dispatch('loadPrices')
      dispatch('loadCategories')
      commit('setCurrencyList', defaultCurrencyList)
      commit('setLanguageList', defaultLanguageList)
      commit('setLastTimeLoaded', currentDate)

      store.dispatch('cart/reloadCart')
    }
  },

  getters: {
    prices: state => state.prices,
    version: state => state.version,
    lastTimeLoaded: state => state.lastTimeLoaded,
    currency: state => state.currency,
    isSelectedCurrencySek: state => state.currency.value === currencySek,
    currencies: state => state.currencies,
    getCurrencyByCode: state => code => state.currencies.find(curr => curr.value === code),
    languages: state => state.languages,
    categories: state => state.categories,
    pageNavigation: state => state.pageNavigation,
    siteConfig: state => state.siteConfig,
    collectionByCollectionPage: state => (collectionPage) => {
      if (!collectionPage) {
        return null
      }

      const collections = state.collectionMap.filter(collection => collection.page === collectionPage)
      if (collections && collections.length > 0) {
        return collections[0].id
      } else {
        return null
      }
    },
    categoryById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const categories = state.categories.filter(category => category.id === id)
      if (categories) {
        return categories[0]
      } else {
        return null
      }
    },
    categoriesByCategoryType: (state) => (categoryType, isCommercialAllowed) => {
      if (!categoryType) {
        return []
      }

      categoryType = parseInt(categoryType)
      const categories = state.categories.filter(category => category.cl_category_type_id === categoryType &&
            (!category.is_commercial || (category.is_commercial && isCommercialAllowed)))
      if (categories) {
        return categories
      } else {
        return []
      }
    },
    priceById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const prices = state.prices.filter(price => price.id === id)
      if (prices) {
        return prices[0]
      } else {
        return null
      }
    },
    priceByCategoryId: (state) => (categoryId, priceClassId) => {
      if (!categoryId || !priceClassId) {
        return null
      }

      categoryId = parseInt(categoryId)
      priceClassId = parseInt(priceClassId)
      const prices = state.prices.filter(price => price.category_id === categoryId && price.cl_price_class_id === priceClassId)
      if (prices) {
        return prices[0]
      } else {
        return null
      }
    },
    pricesByPriceClass: (state) => (priceClassId) => {
      if (!priceClassId) {
        return []
      }

      priceClassId = parseInt(priceClassId)
      const prices = state.prices.filter(price => price.cl_price_class_id === priceClassId)
      if (prices) {
        return prices
      } else {
        return []
      }
    },
    categoriesWithPrices: (state, getters) => (categoryType, isCommercialAllowed, priceClass) => {
      let categories = getters.categoriesByCategoryType(categoryType, isCommercialAllowed)
      const prices = getters.pricesByPriceClass(priceClass)
      categories.forEach(category => {
        const filteredPrices = prices.filter(price => price.category_id === category.id)
          .sort((p1, p2) => (p1.amount > p2.amount) ? 1 : -1)

        let price = {}
        if (filteredPrices) {
          price = filteredPrices[0]
        }
        category.price = price
      })
      categories = categories.filter(category => category.price)
      return categories
    }
  }
}
