import Vue from 'vue'
import App from './App.vue'
import router from './router'

import i18n from '@/plugins/i18n'
import store from '@/store/store'
import Axios from 'axios'
import Meta from 'vue-meta'
import VueGtag from 'vue-gtag'

import vuetify from '@/plugins/vuetify'

import { lastVersion } from '@/store/modules/common'
import formatUtil from '@/js/utils/formatUtil'
import dateUtil from '@/js/utils/dateUtil'

import '@/assets/css/custom.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import ReadMore from 'vue-read-more'

import Hotjar from 'vue-hotjar'
import Rollbar from 'rollbar'

if (process.env.VUE_APP_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: process.env.NODE_ENV === 'production'
  })
}

if (process.env.VUE_APP_ROLLBAR_TOKEN) {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
      code_version: '1.0.0'
    }
  })
}

Vue.use(Loading, {
  color: '#577aa6'
})
Vue.use(VuejsDialog, {
  backdropClose: true
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_CODE },
  enabled: process.env.VUE_APP_GA_CODE,
  pageTrackerUseFullPath: true
}, router)

Vue.prototype.$http = Axios
Vue.config.productionTip = false
Vue.config.performance = false

Vue.use(Meta)

Vue.use(ReadMore)

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err)
  throw err
}
/* Vue.config.errorHandler = function (err, vm, info) {
  console.log('Vue exception handling:')
  console.log(err, vm, info)
}
window.onunhandledrejection = function (evt) {
  console.log('Promise exception handling:')
  console.log(evt)
} */

const currentVersion = store.getters['common/version']
if (lastVersion > currentVersion) {
  console.log('reset store to version: ' + lastVersion)
  store.dispatch('cart/resetState')
  store.dispatch('common/resetState')
  store.dispatch('dashboard/resetState')
  store.dispatch('myMedia/resetState')
  store.dispatch('favorite/resetState')
  store.dispatch('user/resetState')
}

Vue.filter('formatDate', function (value) {
  if (value) {
    return dateUtil.formatDate(value)
  }
})
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return dateUtil.formatDateTime(value)
  }
})
Vue.filter('formatDateVerbal', function (value) {
  if (value) {
    return dateUtil.formatDateVerbal(value)
  }
})
Vue.filter('formatAmount', function (value, currency) {
  return formatUtil.formatAmount(value, currency)
})
Vue.filter('formatInteger', function (value) {
  return formatUtil.formatInteger(value)
})
Vue.filter('formatSize', function (value) {
  return formatUtil.formatSize(value)
})

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
