import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'
import ImageRepository from '@/js/api/ImageRepository'
import i18n from '@/plugins/i18n'

export default {
  getById (postId, isPreview) {
    return new Promise((resolve, reject) => {
      const local = i18n.locale
      var queryMap = new Map()
      queryMap.set('lang', local)
      queryMap.set('extended', true)
      if (isPreview === true) {
        queryMap.set('preview', true)
      }

      var query = urlUtil.prepareQuery(queryMap)
      api
        .get('posts/' + postId + query)
        .then(response => {
          resolve(this._preparePost(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findImagesByPostId (postId, limit, offset) {
    return new Promise((resolve, reject) => {
      var queryMap = new Map()
      queryMap.set('limit', limit)
      queryMap.set('offset', offset)
      queryMap.set('extended', true)
      var query = urlUtil.prepareQuery(queryMap)
      api
        .get('posts/' + postId + '/images' + query)
        .then(response => {
          const postImages = response.data
          const images = []
          postImages.forEach(element => {
            images.push(ImageRepository._prepareImage(element.image))
          })
          resolve(images)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  find (type, collectionPage, limit) {
    return new Promise((resolve, reject) => {
      const local = i18n.locale
      var queryMap = new Map()
      queryMap.set('lang', local)
      queryMap.set('type', type)
      queryMap.set('limit', limit)
      if (collectionPage) {
        queryMap.set('collection-page', collectionPage)
      }
      queryMap.set('extended', true)
      var query = urlUtil.prepareQuery(queryMap)
      api
        .get('posts' + query)
        .then((response) => {
          var posts = response.data
          resolve(this._preparePosts(posts))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _preparePosts (postArray) {
    var resultArray = []
    if (!postArray) {
      return resultArray
    }

    postArray.forEach((post) => {
      resultArray = resultArray.concat(this._preparePost(post))
    })
    return resultArray
  },

  _preparePost (post) {
    if (!post) {
      return null
    }

    post.url = (post.type === 'collection' ? '/collections' : '/news') + '/' + post.id + '/' + encodeURIComponent(post.title)
    post.image = ImageRepository._prepareImage(post.image)
    return post
  }
}
