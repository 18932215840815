import api from '@/js/services/ajax'
import store from '@/store/store'

export default {

  signUpCustomer (email, password, language, termsApproved, agreementId, organization, organizationNumber, firstName, lastName, phoneNo) {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.email = email
      payload.password = password
      payload.language = language
      payload.terms_approved = termsApproved
      payload.agreement_id = agreementId
      payload.organization_name = organization
      payload.organization_number = organizationNumber
      payload.first_name = firstName
      payload.last_name = lastName
      payload.phone_no = phoneNo
      api
        .post('v2/customers', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  signUpMember (firstName, lastName, phoneNo, email, password, aboutMe, language, termsApproved, agreementId, organization, organizationNumber, libraryJoinCode) {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.first_name = firstName
      payload.last_name = lastName
      payload.phone_no = phoneNo
      payload.email = email
      payload.password = password
      payload.about_me = aboutMe
      payload.language = language
      payload.terms_approved = termsApproved
      payload.agreement_id = agreementId
      payload.organization_name = organization
      payload.organization_number = organizationNumber
      payload.library_join_code = libraryJoinCode
      api
        .post('v2/members', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMyUser () {
    return new Promise((resolve, reject) => {
      const userId = store.getters['user/userId']
      api
        .get('v2/users/' + userId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  update (id, user) {
    return new Promise((resolve, reject) => {
      const payload = user
      api
        .put('v2/users/' + id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  login (username, password) {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.email = username
      payload.password = password
      api
        .post('login', payload)
        .then((response) => {
          const user = response.data
          store.dispatch('user/authorize', user)
          resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  requestPasswordReset (email) {
    return new Promise((resolve, reject) => {
      api
        .post('password/request-reset?email=' + email)
        .then((response) => {
          resolve('ok')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setPassword (token, password) {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.token = token
      payload.new_password = password
      api
        .put('password/reset', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getLastUserAgreement () {
    const userId = store.getters['user/userId']
    return new Promise((resolve, reject) => {
      api.get('v2/users/' + userId + '/user-agreement/last')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  signAgreement (agreementId) {
    const userId = store.getters['user/userId']
    return new Promise((resolve, reject) => {
      api.post('v2/user-agreement', {
        user_id: userId,
        agreement_id: agreementId
      })
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

}
