<template>
  <div class="bye-container">
    <div class="bye-logo">
      <svg
        version="1.1"
        id="azote"
        width="300px"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 297.6 60.4"
        style="enable-background: new 0 0 297.6 60.4"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="st0"
              d="M26.5,39.3h-0.1c-1.9,2.5-5.3,4.1-9,4.1c-9,0-14.3-7.4-14.3-14.5c0-6.8,5.6-14.5,14.5-14.5 c4.1,0,7.2,1.8,8.8,4.1h0.1v-3.4h5.9v27.5h-5.9V39.3z M18.1,37.8c4.7,0,8.6-4,8.6-8.9c0-4.9-4-8.9-8.6-8.9c-4.8,0-8.8,4-8.8,8.9 C9.4,33.8,13.3,37.8,18.1,37.8z"
            ></path>
            <path
              class="st0"
              d="M49.8,20.7H37.7v-5.6h22.1L46.4,37.1h13v5.6H36.5L49.8,20.7z"
            ></path>
            <path
              class="st0"
              d="M76.7,14.4c9.6,0,15.2,7.2,15.2,14.5s-5.6,14.5-15.2,14.5c-9.6,0-15.2-7.2-15.2-14.5S67.1,14.4,76.7,14.4z M76.7,37.8c5.2,0,8.9-4,8.9-8.9c0-4.9-3.7-8.9-8.9-8.9c-5.2,0-8.9,4-8.9,8.9C67.8,33.8,71.6,37.8,76.7,37.8z"
            ></path>
            <path
              class="st0"
              d="M94.2,15.1h3.4V7.9l5.9-1.6v8.9h4.7v5.6h-4.7v10.9c0,3,0.7,4.3,2.4,5.3l3.2,1.8l-2.6,4.7 c-6.1-2.9-8.9-5-8.9-10.7V20.7h-3.4V15.1z"
            ></path>
            <path
              class="st0"
              d="M139,36.7c-3.3,4.2-7.8,6.6-12.8,6.7c-9.6,0-15.2-7.2-15.2-14.5s5.6-14.5,15.2-14.5c5.7,0,14.3,4.7,14.3,15.5 v1.1h-22.8c0.3,4.2,4.3,7.2,8.4,7.2c3.2,0,5.9-1.5,8-4.3L139,36.7z M133.6,26c-1.2-4.2-4.4-6.4-8.1-6.4c-3.8,0-6.8,2.3-7.8,6.4 H133.6z"
            ></path>
          </g>
          <g>
            <path
              class="st1"
              d="M163.1,6.5c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3S161.3,6.5,163.1,6.5z M160.1,15.4 h5.9v27.5h-5.9V15.4z"
            ></path>
            <path class="st1" d="M147.1,7.2h5.9v35.7h-5.9V7.2z"></path>
            <path
              class="st1"
              d="M173.1,7.2h5.9v11.5h0.1c1.4-2.3,5.1-4.1,9-4.1c7.2,0,14.3,5.3,14.3,14.5s-7.2,14.5-14.3,14.5 c-3.9,0-7.6-1.8-9-4.1H179v3.4h-5.9V7.2z M187.6,20.2c-4.9,0-8.6,3.8-8.6,8.9s3.6,8.9,8.6,8.9c4.9,0,8.6-3.8,8.6-8.9 S192.6,20.2,187.6,20.2z"
            ></path>
            <path
              class="st1"
              d="M205.9,15.4h5.6v3h0.1c1-2.3,3-3.8,5.4-3.8c1.3,0,2.4,0.3,3.2,0.9l-2.1,5.9c-0.6-0.5-1.6-0.8-2.2-0.8 c-2.2,0-4.1,1.7-4.1,4.2v18.2h-5.9V15.4z"
            ></path>
            <path
              class="st1"
              d="M242.8,39.5h-0.1c-1.9,2.5-5.3,4.1-9,4.1c-9,0-14.3-7.4-14.3-14.5c0-6.8,5.6-14.5,14.5-14.5 c4.1,0,7.2,1.8,8.8,4.1h0.1v-3.4h5.9v27.5h-5.9V39.5z M234.4,38.1c4.7,0,8.6-4,8.6-8.9c0-4.9-4-8.9-8.6-8.9c-4.8,0-8.8,4-8.8,8.9 C225.6,34.1,229.6,38.1,234.4,38.1z"
            ></path>
            <path
              class="st1"
              d="M255.1,15.4h5.6v3h0.1c1-2.3,3-3.8,5.4-3.8c1.3,0,2.4,0.3,3.2,0.9l-2.1,5.9c-0.6-0.5-1.6-0.8-2.2-0.8 c-2.2,0-4.1,1.7-4.1,4.2v18.2h-5.9V15.4z"
            ></path>
            <path
              class="st1"
              d="M272.9,15.4h5.9v17.7c0,2.8,2.3,4.9,4.6,4.9c2.9,0,5.1-2,5.1-4.8V15.4h5.9V42c0,8.4-7.4,12.1-12.6,12.1 c-2.2,0-4-0.3-5.5-0.8l2-5.4c1,0.4,2,0.6,3.2,0.6c3.9,0,7.3-2.4,7.3-7.2v-1.1l-0.1,0.1c-2.1,2-4.3,3.2-6.5,3.2 c-6.1,0-9.4-4.6-9.4-12.3V15.4z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
    <h1 class="bye-headline">Bildbyrån stängd</h1>
    <p class="bye-text" style="text-align: left">
      Efter 20 år har vi nu stängt bildarkivet. Efter flera år av minskande
      försäljning, ökad konkurrens från gratis och billiga stockbilder, och
      under 2023 allt mer AI-genererat material finns det inte längre underlag
      för att driva verksamheten. Vi vill tacka alla fotografer och kunder som
      varit med oss genom åren./Jerker och Robert
    </p>

    <h1 class="bye-headline">Picture library closed</h1>
    <p class="bye-text" style="text-align: left">
      After 20 years, we have now closed the picture archive. After several
      years of declining sales, increased competition from free and cheap stock
      images, and in 2023, a growing presence of AI-generated material, there is
      no longer a basis for continuing the business. We would like to thank all
      the photographers and customers who have been with us throughout the
      years./Jerker and Robert
    </p>
    <div class="d-inline-flex gap-2 mb-5"></div>
  </div>
</template>

<script>
export default {
  name: 'Bye'
}
</script>

<style lang="scss" scoped>
.st0 {
  fill: #577aa7;
}
.st1 {
  fill: #9cb6d8;
}

.bye-container {
  padding: 48px;
  border-radius: 8px;
  background-color: rgb(248, 249, 250);
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bye-headline {
  padding-top: 48px;
  margin-bottom: 8px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.bye-text {
  font-weight: 400;
  //   line-height: 30px;
  color: rgba(33, 37, 41, 0.75);
  max-width: 60ch;
  font-size: 20px;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>
