/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import i18n from '@/plugins/i18n'
import Bye from '@/views/Bye.vue'
// import routes from './routes'

import locales, { SUPPORTED_LOCALES } from '../locales/locales.js'

import urlUtil from '@/js/utils/urlUtil.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      // path: "*",
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: Bye,
      meta: {
        requiresAuth: false
      }
    }
  ]
})

export default router
