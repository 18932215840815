<template>
  <v-app app>
    <!-- <TheHeader /> -->

    <v-main class="az-main-content">
      <router-view />
    </v-main>

    <!-- <TheFooter /> -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      webpSupported: true
    }
  },
  components: {}
}
</script>

<style>
.az-main-content {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (max-width: 768px) {
  .az-main-content {
    max-width: calc(100vw - 16px);
  }
}
</style>
