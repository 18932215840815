import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/assets/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#577aa6',
        secondary: '#9cb5d9',
        accent: '#230',
        error: '#b71c1c'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
