import CartRepository from '@/js/api/CartRepository'
import toast from '@/js/services/toast'
import store from '@/store/store'
import errorUtil from '../../js/utils/errorUtil'
import router from '@/router'

const getDefaultState = () => {
  return {
    id: '',
    items: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setCart (state, cartPayload) {
      if (cartPayload.cart) {
        state.id = cartPayload.cart.id
        state.items = cartPayload.items
      } else {
        Object.assign(state, getDefaultState())
      }
    },
    resetItemList (state, items) {
      state.items = items
    },
    addItem (state, item) {
      state.items.push(item)
    },
    updateItem (state, item) {
      state.items = state.items.filter((value) => value.id !== item.id)
      state.items.push(item)
    },
    removeItem (state, itemId) {
      state.items = state.items.filter((value) => value.id !== itemId)
    },
    removeAllItems (state) {
      state.items = []
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    async addItem ({ commit, state, getters }, item) {
      try {
        let nonUserToken = store.getters['user/nonUserToken']
        if (!state.id || !nonUserToken) {
          const cartPayload = await CartRepository.initCart(true)
          if (!cartPayload || !cartPayload.cart) {
            throw new Error('Cart not found!')
          }

          commit('setCart', cartPayload)
          if (!nonUserToken) {
            nonUserToken = cartPayload.cart.non_user_token
            store.dispatch('user/setNonUserToken', nonUserToken)
          }
        }

        let categoryId = item.category_id
        if (!categoryId && item.image) {
          const categoryType = item.image.cl_category_type_id
          const priceClass = item.image.cl_price_class_id
          const isCommercialAllowed = item.image.is_commercial_allowed
          const firstCategory = store.getters['common/categoriesWithPrices'](categoryType, isCommercialAllowed, priceClass)[0]
          if (firstCategory) {
            categoryId = firstCategory.id
          }
        }

        const imageId = item.image_id
        if (!imageId) {
          throw new Error('Image not found in item ' + JSON.stringify(item))
        }

        const foundItem = getters.getItemByImageId(imageId)
        if (foundItem) {
          toast.error('Item already added!')
          return
        }

        const cartItem = await CartRepository.addItem(state.id, imageId, categoryId)
        commit('addItem', cartItem)
      } catch (error) {
        errorUtil.error('Failed to add cart item ' + item, error)
      }
    },

    async updateItem ({ commit, state }, item) {
      try {
        await CartRepository.updateItem(state.id, item.id, item.image_id, item.category_id, item.end_user)
        commit('updateItem', item)
      } catch (error) {
        errorUtil.error('Failed to update cart item ' + item, error)
      }
    },

    async removeItem ({ commit, state }, itemId) {
      try {
        await CartRepository.removeItem(state.id, itemId)
        commit('removeItem', itemId)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          store.dispatch('cart/reloadCart')
          router.go()
          return
        }
        errorUtil.error('Failed to remove cart item ' + itemId, error)
      }
    },

    async clearCart ({ commit, state }) {
      try {
        await CartRepository.removeAllItem(state.id)
        commit('removeAllItems')
      } catch (error) {
        errorUtil.error('Failed to clear cart', error)
      }
    },

    async reloadCart ({ commit }) {
      const nonUserToken = store.getters['user/nonUserToken']
      const userLoggedIn = store.getters['user/isLoggedIn']
      if (nonUserToken || userLoggedIn === true) {
        const cartPayload = await CartRepository.initCart(false)
        commit('setCart', cartPayload)
      }
    }
  },

  getters: {
    id: state => state.id,
    items: state => state.items.sort((a, b) => Number(b.id) - Number(a.id)),
    count: state => state.items.length,
    getItemById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const itemsById = state.items.filter(item => item.id === id)
      if (itemsById) {
        return itemsById[0]
      } else {
        return null
      }
    },
    getItemByImageId: (state) => (imageId) => {
      if (!imageId) {
        return null
      }

      imageId = parseInt(imageId)
      const itemsByImage = state.items.filter(item => item.image_id === imageId)
      if (itemsByImage) {
        return itemsByImage[0]
      } else {
        return null
      }
    }
  }
}
