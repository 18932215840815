import FavoriteRepository from '@/js/api/FavoriteRepository'
import store from '@/store/store'
import errorUtil from '../../js/utils/errorUtil'

const getDefaultState = () => {
  return {
    imageIds: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    addItem (state, imageId) {
      state.imageIds.push(imageId)
    },
    removeItem (state, imageId) {
      state.imageIds = state.imageIds.filter((value) => value !== imageId)
    },
    resetImages (state, images) {
      state.imageIds = images.map(image => image.id)
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    addImage ({ commit }, imageId) {
      const isLoggedIn = store.getters['user/isLoggedIn']
      if (isLoggedIn) {
        FavoriteRepository.addFavorite(imageId)
          .catch((error) => {
            errorUtil.error('Failed to add image to favorites', error)
          })
      }
      commit('addItem', imageId)
    },

    removeImage ({ commit }, imageId) {
      const isLoggedIn = store.getters['user/isLoggedIn']
      if (isLoggedIn) {
        FavoriteRepository.removeFavorite(imageId)
          .catch((error) => {
            errorUtil.error('Failed to remove image from favorites', error)
          })
      }
      commit('removeItem', imageId)
    },

    reload ({ commit }, shouldClearOut) {
      const isLoggedIn = store.getters['user/isLoggedIn']
      if (isLoggedIn) {
        FavoriteRepository.getAll()
          .then((result) => {
            const images = result.map(favorite => favorite.image)
            commit('resetImages', images)
          })
      }
      if (shouldClearOut === true) {
        commit('resetImages', [])
      }
    }
  },
  getters: {
    imageIds: state => state.imageIds
  }
}
