import DashboardImageRepository from '@/js/api/DashboardImageRepository'
import objectUtil from '@/js/utils/objectUtil'
import stringUtil from '@/js/utils/stringUtil'
import { ValidationError } from '@/js/services/validationError'
import i18n from '@/plugins/i18n'

const getDefaultState = () => {
  return {
    mediaList: [],
    copiedMedia: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    processMedia (state, id) {
      const media = state.mediaList.find(media => media.id === id)
      media._state = 'processing'
    },
    processFailed (state, id) {
      const media = state.mediaList.find(media => media.id === id)
      media._state = 'failed'
    },
    processCompleted (state, id) {
      const media = state.mediaList.find(media => media.id === id)
      media._state = 'completed'
    },
    setMediaList (state, mediaList) {
      state.mediaList = objectUtil.clone(mediaList)
    },
    updateMedia (state, media) {
      if (!media) {
        return
      }

      media = objectUtil.clone(media)
      const item = state.mediaList.find(item => item.id === media.id)
      Object.assign(item, media)
    },
    storeCopiedMedia (state, payload) {
      state.copiedMedia = objectUtil.clone(payload)
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },
    clearMediaList ({ commit }) {
      commit('setMediaList', [])
    },
    updateMediaItem ({ commit }, media) {
      if (!media) {
        return
      }

      commit('updateMedia', media)
    },

    getMediaList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        DashboardImageRepository.getUserImages(payload.status, payload.rangeStart, payload.rangeEnd, payload.type,
          payload.orientation, payload.model, payload.feedbackId, payload.query, payload.orderBy, payload.orderByDirection, payload.pageSize, payload.page)
          .then(result => {
            const returnObj = {}
            returnObj.countTotalBeforeLimit = result.countTotalBeforeLimit
            returnObj.count_prepare = result.count_prepare
            returnObj.count_new = result.count_new
            returnObj.count_pending = result.count_pending
            returnObj.count_offline = result.count_offline
            returnObj.count_online = result.count_online
            commit('setMediaList', result.images)
            resolve(returnObj)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    startReview ({ state, getters, commit }, id) {
      return new Promise((resolve, reject) => {
        try {
          if (!id) {
            throw new Error('No id specified')
          }

          commit('processMedia', id)
          const media = getters.getMediaById(id)
          if (!media) {
            throw new Error('Media not found for publish operation by id: ' + id)
          }

          if (!media.model && media.model !== 0) {
            throw new ValidationError(i18n.t('dashboard.manage-my-images.validation.model-missing'))// '<b>Missing model release!</b> Please specify model relase to publish media!'
          }
          if (media.stars < 6) {
            throw new ValidationError(i18n.t('dashboard.manage-my-images.validation.low-star-count'))// '<b>Low quality of information!</b> Fill information to get at least <b>3</b> stars to publish media!')
          }
          if (!media.title_en || !media.title_sv) {
            throw new ValidationError(i18n.t('dashboard.manage-my-images.validation.title-missing'))// '<b>Missing title!</b> Specify title in Swedish and English to publish media!')
          }
          if (media.keywords_en.length < 6 || media.keywords_sv.length < 6) {
            throw new ValidationError(i18n.t('dashboard.manage-my-images.validation.keywords'))// '<b>Missing or too few keywords!</b> Specify at least 5 keywords in all languages to publish media!')
          }

          DashboardImageRepository.startReview(id)
            .then((updatedMedia) => {
              commit('updateMedia', updatedMedia)
              commit('processCompleted', id)
              resolve(updatedMedia)
            })
            .catch(error => {
              commit('processFailed', id)
              reject(error)
            })
        } catch (error) {
          commit('processFailed', id)

          if (error instanceof ValidationError) {
            reject(error)
          } else {
            reject(new Error('Failed to publish media by id: ' + id, error))
          }
        }
      })
    },

    setOffline ({ getters, commit }, id) {
      return new Promise((resolve, reject) => {
        try {
          if (!id) {
            throw new Error('No id specified')
          }

          commit('processMedia', id)
          const media = getters.getMediaById(id)
          if (!media) {
            throw new Error('Media not found for offline operation by id: ' + id)
          }

          DashboardImageRepository.setOffline(id)
            .then((updatedMedia) => {
              commit('updateMedia', updatedMedia)
              commit('processCompleted', id)
              resolve(updatedMedia)
            })
            .catch(error => {
              commit('processFailed', id)
              reject(error)
            })
        } catch (error) {
          commit('processFailed', id)
          reject(new Error('Failed to set offline media by id: ' + id, error))
        }
      })
    },

    deleteMedia ({ state, getters, commit }, id) {
      return new Promise((resolve, reject) => {
        try {
          if (!id) {
            throw new Error('No id specified')
          }

          commit('processMedia', id)

          const media = getters.getMediaById(id)
          if (!media) {
            throw new Error('Media not found for delete operation by id: ' + id)
          }

          DashboardImageRepository.delete(id)
            .then((resp) => {
              media.status = 'deleted'
              commit('updateMedia', media)
              commit('processCompleted', id)
              resolve('OK')
            })
            .catch(error => {
              commit('processFailed', id)
              reject(error)
            })
        } catch (error) {
          commit('processFailed', id)
          reject(new Error('Failed to delete media by id: ' + id, error))
        }
      })
    },

    pasteToMedia ({ getters, commit }, id) {
      return new Promise((resolve, reject) => {
        let media = null
        try {
          if (!id) {
            throw new Error('Media id not presented!')
          }

          const mediaFrom = getters.getMediaCopy
          if (!mediaFrom) {
            throw new Error('Missing media to use for source')
          }

          commit('processMedia', id)
          media = getters.getMediaById(id)
          if (!media) {
            throw new Error('Failed to past content/keywords to media. Media not found by id ' + id)
          }

          let keywords = []
          keywords = keywords
            .concat(stringUtil.stringToArray(mediaFrom.keywords_sv)
              .map(item => { return { title: item, lang: 'sv', tag_type: 'copy', confidence: 100 } })
            )
            .concat(stringUtil.stringToArray(mediaFrom.keywords_en)
              .map(item => { return { title: item, lang: 'en', tag_type: 'copy', confidence: 100 } })
            )
            .concat(stringUtil.stringToArray(mediaFrom.keywords_taxonomic)
              .map(item => { return { title: item, lang: 'tax', tag_type: 'copy', confidence: 100 } })
            )

          media.title_sv = mediaFrom.title_sv
          media.title_en = mediaFrom.title_en
          media.caption_sv = mediaFrom.caption_sv
          media.caption_en = mediaFrom.caption_en
          media.locations_sv = mediaFrom.locations_sv
          media.locations_en = mediaFrom.locations_en
          media.restrictions_sv = mediaFrom.restrictions_sv
          media.restrictions_en = mediaFrom.restrictions_en
          media.model = mediaFrom.model
          media.author = mediaFrom.author
          media.similar_media_id = mediaFrom.id

          DashboardImageRepository.updateImage(media, keywords, null, null)
            .then(updatedMedia => {
              commit('updateMedia', updatedMedia)
              commit('processCompleted', id)
              resolve(media)
            })
            .catch(err => {
              commit('processFailed', id)
              reject(err)
            })
        } catch (error) {
          commit('processFailed', id)
          reject(new Error('Update media from copied item failed for media id:' + id, error))
        }
      })
    },

    copyMediaInMemory ({ commit }, media) {
      if (!media) {
        return
      }

      commit('storeCopiedMedia', media)
    }
  },

  getters: {
    mediaList: (state) => state.mediaList,
    getMediaCopy: (state) => state.copiedMedia,
    getMediaById: (state) => (imageId) => {
      if (!imageId) {
        return null
      }

      imageId = parseInt(imageId)
      const mediaById = state.mediaList.filter(media => media.id === imageId)
      if (mediaById) {
        return mediaById[0]
      } else {
        return null
      }
    }
  }
}
