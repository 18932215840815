import api from '@/js/services/ajax'
import ImageRepository from '@/js/api/ImageRepository'
import OtherRepository from './OtherRepository'

export default {

  async initCart (createCart) {
    try {
      const cartPayload = {}
      cartPayload.cart = await this.getCartLatest()

      if (!cartPayload.cart) {
        if (createCart) {
          cartPayload.cart = await this.createCart()
          if (!cartPayload.cart) {
            throw new Error('Failed to create cart')
          }
        }

        cartPayload.items = []
      } else {
        const cartId = cartPayload.cart.id
        cartPayload.items = await this.getItems(cartId)
      }

      return cartPayload
    } catch (error) {
      console.log(error)
      // throw new Error('Failed to initialise cart!', error)
      throw error
    }
  },

  createCart () {
    return new Promise((resolve, reject) => {
      api
        .post('v2/carts')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCartLatest () {
    return new Promise((resolve, reject) => {
      api
        .get('v2/carts/latest')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCartById (id) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/carts/' + id)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getItems (cartId) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/carts/' + cartId + '/items?extended=true')
        .then(response => {
          resolve(this._prepareCartItems(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getItem (cartId, itemId) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/carts/' + cartId + '/items/' + itemId + '?extended=true')
        .then(response => {
          resolve(this._prepareCartItem(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addItem (cartId, imageId, categoryId, endUser) {
    const json = {}
    json.image_id = imageId
    json.category_id = categoryId
    json.end_user = endUser
    return new Promise((resolve, reject) => {
      api
        .post('v2/carts/' + cartId + '/items/', json)
        .then(response => {
          resolve(this._prepareCartItem(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem (cartId, itemId, imageId, categoryId, endUser) {
    const json = {}
    json.image_id = imageId
    json.category_id = categoryId
    json.end_user = endUser
    return new Promise((resolve, reject) => {
      api
        .put('v2/carts/' + cartId + '/items/' + itemId, json)
        .then(response => {
          resolve(this._prepareCartItem(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItemBy (item) {
    const cartId = item.cart_id
    const itemId = item.id
    return new Promise((resolve, reject) => {
      api
        .put('v2/carts/' + cartId + '/items/' + itemId, item)
        .then(response => {
          resolve(this._prepareCartItem(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem (cartId, itemId) {
    return new Promise((resolve, reject) => {
      api
        .delete('v2/carts/' + cartId + '/items/' + itemId)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeAllItem (cartId) {
    return new Promise((resolve, reject) => {
      api
        .delete('v2/carts/' + cartId + '/items/')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  checkout (id, data) {
    return new Promise((resolve, reject) => {
      api.post('v2/carts/' + id + '/checkout', data)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareCartItems (array) {
    var resultArray = []
    if (!array) {
      return resultArray
    }

    array.forEach((item) => {
      resultArray = resultArray.concat(this._prepareCartItem(item))
    })
    return resultArray
  },

  _prepareCartItem (item) {
    if (!item) {
      return null
    }

    if (item.image) {
      item.image = ImageRepository._prepareImage(item.image)
    }
    if (!item.image_id && item.image) {
      item.image_id = item.image.id
    }

    if (item.category) {
      item.category = OtherRepository._prepareCategory(item.category)
    }

    return item
  }
}
