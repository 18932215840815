import toast from '@/js/services/toast'
import { ValidationError } from '@/js/services/validationError'
import i18n from '@/plugins/i18n'

export default {
  FIELD_ERROR_BAD_VALUE: 'BAD_VALUE',

  notify (error) {
    if (error instanceof ValidationError) {
      toast.error(error.message)
    } else {
      throw new Error('Unknown notification error!', error)
    }
  },

  error (message, error, additionalData) {
    console.warn('Error ' + message)
    console.warn(error)
    console.warn(additionalData)

    if (!error) {
      // no error defined!
      toast.error(message)
      throw new Error('Undefined error: ' + message)
    }

    const response = error.response
    if (!response) {
      toast.error(i18n.t('error.system'))
      throw new Error('Web error: ' + message + ', ' + error)
    }

    const respData = response.data
    if (respData && respData.key) {
      const errorConstant = 'error.api-response.' + respData.key.toLowerCase()
      const errorMessage = i18n.t(errorConstant)
      if (errorMessage === errorConstant) {
        toast.error(i18n.t('error.system'))
        throw new Error('Missing API translation: ' + errorConstant)
      }
      toast.error(errorMessage)
      return
    }

    const status = response.status
    if (status) {
      if (status >= 500) {
        toast.error(i18n.t('error.system'))
        throw new Error('Api error 500: ' + message + ', ' + response)
      } else if (status >= 400) {
        toast.error(i18n.t('error.api-response.bad-request'))
      }
    }
  },

  log (message, error) {
    console.log('Got error to log!', message, error)
  },

  /** Return error data if exists */
  getErrorData (error) {
    if (!error || !error.response || !error.response.data) {
      return null
    }

    return error.response.data
  },

  getFieldError (error, fieldName) {
    if (!error || !error.response ||
          !error.response.data || !error.response.data.fields) {
      return null
    }

    const fields = error.response.data.fields
    const fieldErrors = fields.filter(field => {
      if (field.name === fieldName) {
        return field
      }
    })

    if (fieldErrors.length > 0) {
      return fieldErrors[0]
    }
    return null
  }
}
