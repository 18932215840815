import api from '@/js/services/ajax'

export default {

  findByImage (imageId) {
    return new Promise((resolve, reject) => {
      api
        .get('/collection-image?imageId=' + imageId)
        .then((res) => {
          resolve(this._prepareCollectionImages(res.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  findAll () {
    return new Promise((resolve, reject) => {
      api
        .get('/collection-users')
        .then((res) => {
          resolve(this._prepareCollectionUsers(res.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  joinCollection (code) {
    return new Promise((resolve, reject) => {
      api
        .post('/collection-users', { code: code })
        .then((res) => {
          resolve(this._prepareCollectionUser(res.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  leaveCollection (collectionUserId) {
    return new Promise((resolve, reject) => {
      api
        .post('/collection-users/' + collectionUserId + '/leave')
        .then((res) => {
          resolve('ok')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareCollectionUsers (postUsers) {
    var resultArray = []
    if (!postUsers) {
      return resultArray
    }

    postUsers.forEach((postUser) => {
      resultArray = resultArray.concat(this._prepareCollectionUser(postUser))
    })
    return resultArray
  },
  _prepareCollectionUser (postUser) {
    postUser.url = '/collections/' + postUser.post_id + '/' + encodeURIComponent(postUser.post_title)
    return postUser
  },

  _prepareCollectionImages (collectionImages) {
    var resultArray = []
    if (!collectionImages) {
      return resultArray
    }

    collectionImages.forEach((collectionImage) => {
      resultArray = resultArray.concat(this._prepareCollectionImage(collectionImage))
    })
    return resultArray
  },
  _prepareCollectionImage (collectionImage) {
    return collectionImage
  }
}
