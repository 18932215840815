import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './modules/user'
import dashBoardModules from './modules/dashboard'
import myMediaModules from './modules/myMedia'
import cartModule from './modules/cart'
import favoriteModule from './modules/favorite'
import commonModule from './modules/common'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    cart: cartModule,
    favorite: favoriteModule,
    common: commonModule,
    dashboard: dashBoardModules,
    myMedia: myMediaModules
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['user', 'cart', 'favorite'],
      key: 'azss'
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['common', 'dashboard', 'myMedia'],
      key: 'azls'
    })
  ]
})
