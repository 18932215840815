import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'
import ImageRepository from '@/js/api/ImageRepository'

export default {
  updateImage (image, keywords, collections, resolveImageFeedbackId) {
    if (!image || !image.id) {
      throw new Error('Problems with image parameter: ' + image, image)
    }

    const json = {}
    json.title_sv = image.title_sv
    json.title_en = image.title_en
    json.caption_sv = image.caption_sv
    json.caption_en = image.caption_en
    json.restrictions_sv = image.restrictions_sv
    json.restrictions_en = image.restrictions_en
    json.locations_sv = image.locations_sv
    json.locations_en = image.locations_en
    json.image_type = image.image_type
    json.color = image.color
    json.orientation = image.orientation
    json.original = image.original
    json.model = image.model
    json.author = image.author
    json.date_taken = image.date_taken
    json.similar_media_id = image.similar_media_id

    json.resolve_image_feedback_id = resolveImageFeedbackId

    if (keywords) {
      json.keywords = keywords
    }
    if (collections) {
      json.collections = collections
    }

    return new Promise((resolve, reject) => {
      api.patch('v2/images/' + image.id, json)
        .then((res) => {
          let image = res.data
          image = this._prepareImage(image)
          resolve(image)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateKeywordsOnImage (keywords) {
    return new Promise((resolve, reject) => {
      api.push('v2/keywords', keywords)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchKeywords (word, lang) {
    return new Promise((resolve, reject) => {
      api
        .get(`v2/keywords/getKeywordsByString/${word}?lang=${lang}`)
        .then((res) => {
          resolve(res.data.keywords)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserImages (status, rangeStart, rangeEnd, type, orientation, model, feedbackId, search, orderBy, orderByDirection, pageSize, page) {
    const queryMap = new Map()
    queryMap.set('status', status)
    queryMap.set('dateStart', rangeStart)
    queryMap.set('dateEnd', rangeEnd)
    queryMap.set('filterType', type)
    queryMap.set('filterOrientation', orientation)
    queryMap.set('filterSearchTerm', search)
    queryMap.set('filterModel', model)
    queryMap.set('feedbackId', feedbackId)
    queryMap.set('orderBy', orderBy)
    queryMap.set('orderByDirection', orderByDirection)
    queryMap.set('pageSize', pageSize)
    queryMap.set('page', page)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`v2/images${query}`)
        .then((res) => {
          const data = res.data

          const result = {}
          result.images = this._prepareImages(data.images, data.imageCollectionMap)
          result.countTotalBeforeLimit = data.countTotalBeforeLimit
          result.count_prepare = data.count_prepare
          result.count_new = data.count_new
          result.count_pending = data.count_pending
          result.count_offline = data.count_offline
          result.count_online = data.count_online
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getImageById (id) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/images/' + id)
        .then((res) => {
          resolve(this._prepareImage(res.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getImageKeywordsByImage (imageId) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/images/' + imageId + '/keywords')
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getImageCollections (imageId) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/images/' + imageId + '/collections')
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getImageSuggestions (imageId) {
    return new Promise((resolve, reject) => {
      api
        .get('v2/images/' + imageId + '/suggestions')
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setCoverForVideo (mediaId, milisecond) {
    return new Promise((resolve, reject) => {
      api.post('v2/images/' + mediaId + '/changeCover', { milisec: milisecond })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  delete (id) {
    return new Promise((resolve, reject) => {
      api
        .post('v2/images/' + id + '/delete')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  startReview (id) {
    return new Promise((resolve, reject) => {
      api
        .post('v2/images/' + id + '/start-review')
        .then((response) => {
          resolve(this._prepareImage(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setOffline (id) {
    return new Promise((resolve, reject) => {
      api
        .post('v2/images/' + id + '/set-offline')
        .then((response) => {
          resolve(this._prepareImage(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareImages (imageArray, imageCollectionMap) {
    var resultArray = []
    if (!imageArray) {
      return resultArray
    }

    imageArray.forEach((image) => {
      const imageCollections = imageCollectionMap.filter(imageCollection => imageCollection.image_id === image.id)
      resultArray = resultArray.concat(this._prepareImage(image, imageCollections))
    })
    return resultArray
  },
  _prepareImage (image, imageCollections) {
    image = ImageRepository._prepareImage(image)

    if (!imageCollections) {
      imageCollections = []
    }
    image.collections = imageCollections

    image._state = null// possible states: {null - nothing, processing -> media is being processed at this moment, failed -> process operation failed, completed -> process completed}
    return image
  }
}
