import store from '@/store/store'
import router from '@/router'
import api from '@/js/services/ajax'
import InvoiceRepository from '@/js/api/InvoiceRepository'
import UserRepository from '@/js/api/UserRepository'
import errorUtil from '../../js/utils/errorUtil'
import dateUtil from '@/js/utils/dateUtil'

const getDefaultState = () => {
  return {
    nonUserToken: '',
    token: '',
    user: '',
    purchasedItemCount: 0,
    lastSignedAgreement: null,
    lastCheckForSignedAgreement: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, payload) {
      state.token = payload.token
      state.user = {}
      state.user.id = payload.id
      state.user.role = payload.role
      state.user.allowPayLater = payload.allow_pay_later === 1
    },
    setUserDetails (state, user) {
      state.user.email = user.email
      state.user.fullName = user.full_name
      state.user.initial = user.initial
      state.user.countryId = user.country_id
      state.user.dateCreated = user.date_created
      state.user.isPartner = user.is_partner
    },
    setPurchasedItemCount (state, count) {
      state.purchasedItemCount = count
    },
    logout (state) {
      Object.assign(state, getDefaultState())
    },
    setNonUserToken (state, nonUserToken) {
      state.nonUserToken = nonUserToken
    },
    setLastSignedAgreement (state, lastSignedAgreement) {
      state.lastSignedAgreement = lastSignedAgreement
      state.lastCheckForSignedAgreement = new Date()
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    setNonUserToken ({ commit }, nonUserToken) {
      commit('setNonUserToken', nonUserToken)
    },

    changeUserDetails ({ commit }, user) {
      commit('setUserDetails', user)
    },

    authorize ({ commit }, userPayload) {
      commit('setUser', userPayload)
      commit('setUserDetails', userPayload)
      store.dispatch('user/loadPurchasedItemCount')
      store.dispatch('user/loadLastSignedAgreement', true)
      store.dispatch('favorite/reload', false)
      store.dispatch('common/reloadDefaultLists', true)
      store.dispatch('dashboard/reloadDefaultLists')
      store.dispatch('dashboard/setDefaultCollection', userPayload.default_collection_id)
    },

    logout ({ commit, getters }) {
      api
        .post('logout')
        .then((response) => {
          store.dispatch('user/removeLoggedInUserData')
        })
        .catch((error) => {
          if (error.response.status === 401) {
            store.dispatch('user/removeLoggedInUserData')
            return
          }
          errorUtil.error('Failed to log out', error)
        })
    },

    removeLoggedInUserData ({ commit, getters }) {
      const nonUserToken = getters.nonUserToken
      commit('logout')

      commit('setNonUserToken', nonUserToken)
      store.dispatch('favorite/reload', true)
      store.dispatch('common/reloadDefaultLists', true)
      store.dispatch('dashboard/resetState')
      router.go()
    },

    loadPurchasedItemCount ({ commit }) {
      InvoiceRepository.getPurchasedItems()
        .then((items) => {
          const purchasedItemsCount = items.length
          commit('setPurchasedItemCount', purchasedItemsCount)
        })
    },
    updatePurchasedItemCount ({ commit, getters }) {
      const purchaseCount = getters.purchaseCount + 1
      commit('setPurchasedItemCount', purchaseCount)
    },
    loadLastSignedAgreement ({ commit, getters }, force) {
      return new Promise((resolve, reject) => {
        const lastCheckForSignedAgreement = getters.lastCheckForSignedAgreement
        if (force || !dateUtil.isToday(lastCheckForSignedAgreement)) {
          UserRepository.getLastUserAgreement()
            .then((agreement) => {
              commit('setLastSignedAgreement', agreement)
              resolve(agreement)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          const lastSignedAgreement = getters.lastSignedAgreement
          resolve(lastSignedAgreement)
        }
      })
    }
  },
  getters: {
    token: state => state.token,
    nonUserToken: state => state.nonUserToken,
    user: state => state.user,
    userId: state => state.user.id,
    countryId: state => state.user.countryId,
    allowPayLater: state => state.user.allowPayLater,
    isLoggedIn: state => !!state.token,
    role: state => state.user.role,
    isOnlyCustomer: state => state.user.role === 'user',
    fullName: state => state.user.fullName,
    initial: state => state.user.initial,
    userDateCreated: state => state.user.dateCreated,
    purchaseCount: state => state.purchasedItemCount,
    isPartner: state => state.user.isPartner,
    isActualAgreementSigned: state => {
      if (state.lastSignedAgreement) {
        return state.lastSignedAgreement.is_actual_agreement
      }
      return false
    },
    lastSignedAgreement: state => state.lastSignedAgreement,
    lastCheckForSignedAgreement: state => state.lastCheckForSignedAgreement
  }
}
