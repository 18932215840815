import axios from 'axios'
import store from '@/store/store'
import i18n from '@/plugins/i18n'
import urlUtil from '@/js/utils/urlUtil'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use(
  config => {
    const token = store.getters['user/token']
    if (token) {
      config.headers.Authorization = token
    }

    const nonUserToken = store.getters['user/nonUserToken']
    if (nonUserToken) {
      config.headers.NonUserToken = nonUserToken
    }

    const path = urlUtil.getUrlWithoutLocale(window.location.pathname).toLowerCase()
    config.headers['X-Member-Portal'] = path.startsWith('/private')

    config.headers.language = i18n.locale

    // To be able to use different version for some resources
    var url = config.url.toLowerCase()
    if (url.startsWith('/')) {
      url = url.slice(1)
    }

    if (url.startsWith('v2/')) {
      var baseUrl = config.baseURL
      baseUrl = baseUrl.slice(0, -3)
      config.baseURL = baseUrl
    }
    return config
  },

  error => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => {
    return response
  },

  (error) => {
    const resp = error.response
    if (resp && resp.status === 401) {
      if (store.getters['user/isLoggedIn'] === true) {
        store.dispatch('user/logout')
      }
    }

    return Promise.reject(error)
  })

export default api
