import locales from '@/locales/locales.js'

export default {
  prepareQuery (queryMap) {
    var query = ''
    for (var [key, value] of queryMap.entries()) {
      if (!query) {
        query += '?'
      } else {
        query += '&'
      }

      if (value === 0) {
        value = '0'
      } else if (!value) {
        value = ''
      }

      query += key + '=' + this.encodeParam(value)
    }
    return query
  },

  encodeParam (value) {
    if (!value) {
      return ''
    }
    return encodeURIComponent(value)
  },

  getLocalisedUrl (url) {
    if (!url) {
      return null
    }
    if (url && url.startsWith('https://')) {
      return url
    }

    if (url.startsWith('/')) {
      url = url.substring(1)
    }

    const locale = locales.getLocale()
    return locale.base + '/' + url
  },

  getUrlInLocale (lang, url) {
    if (!lang) {
      return null
    }
    const locale = locales.getLocaleByCode(lang)
    if (!locale) {
      return null
    }

    if (!url) {
      return locale.base
    }

    if (url.startsWith('/')) {
      url = url.substring(1)
    }

    return locale.base + '/' + url
  },

  getUrlWithoutLocale () {
    let url = (window.location.pathname ?? '') + (window.location.search ?? '') + (window.location.hash ?? '')
    var re = new RegExp('^\\/(sv|en)($|\\/|\\?|#)')
    if (re.test(url)) {
      url = url.substring(3)
    }
    return url
  },

  getLangFromUrl () {
    var locale = null
    var url = window.location.pathname
    var re = new RegExp('^\\/(sv|en)($|\\/|\\?|#)')
    if (re.test(url)) {
      locale = url.substring(1, 3)
    }
    return locale
  }
}
