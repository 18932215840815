import api from '@/js/services/ajax'
import i18n from '@/plugins/i18n'

export default {

  getSiteConfig () {
    return new Promise((resolve, reject) => {
      api.get('v2/site-configs/main')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findCountries () {
    return new Promise((resolve, reject) => {
      api.get('countries')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findCategories () {
    return new Promise((resolve, reject) => {
      api.get('v2/category')
        .then(response => {
          var categories = response.data

          var resultArray = []
          if (!categories) {
            resolve(resultArray)
            return
          }

          categories.forEach((category) => {
            resultArray = resultArray.concat(this._prepareCategory(category))
          })
          resolve(resultArray)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  findAllCategories () {
    return new Promise((resolve, reject) => {
      api.get('v2/category/all')
        .then(response => {
          var categories = response.data

          var resultArray = []
          if (!categories) {
            resolve(resultArray)
            return
          }

          categories.forEach((category) => {
            resultArray = resultArray.concat(this._prepareCategory(category))
          })
          resolve(resultArray)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  _prepareCategory (category) {
    if (!category) {
      return null
    }

    const local = i18n.locale
    category.category_name = category['category_name_' + local]
    category.price_name = category['price_name_' + local]

    return category
  }

}
