import locales from '@/locales/locales.js'
import stringUtil from '@/js/utils/stringUtil'

export default {
  getDateWithoutTimezone (date) {
    if (stringUtil.isEmpty(date)) {
      return null
    }

    try {
      date = new Date(date)
      return new Date(date - date.getTimezoneOffset() * 60000).toISOString().substr(0, 10)
    } catch (e) {
      console.error('Failed to convert date => ', date)
      return null
    }
  },

  isToday (someDate) {
    if (stringUtil.isEmpty(someDate)) {
      return false
    }

    someDate = new Date(someDate)
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  },

  formatDate (date) {
    if (!date) {
      return '-'
    }

    if (!(date instanceof Date)) {
      date = new Date(date.replace(' ', 'T'))
    }
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '.' + month + '.' + year
  },

  formatDateTime (date) {
    if (!date) {
      return '-'
    }

    if (!(date instanceof Date)) {
      date = new Date(date.replace(' ', 'T'))
    }
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hh = date.getHours()
    const min = date.getMinutes()

    const result = day + '.' + month + '.' + year + ' ' + hh + ':' + min
    return result
  },

  formatDateVerbal (date) {
    if (!date) {
      return '-'
    }

    if (!(date instanceof Date)) {
      date = new Date(date.replace(' ', 'T'))
    }
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)

    var options = { year: 'numeric', month: 'long', day: 'numeric', formatMatcher: 'basic' }
    const local = locales.getLocale()
    if (local && local.code === 'sv') {
      return date.toLocaleDateString('sv-SE', options)
    }
    return date.toLocaleDateString('en-US', options)
  }
}
