import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'
import ImageRepository from '@/js/api/ImageRepository'

export default {
  getUserSales (userId, dateFrom, dateTill) {
    var queryMap = new Map()
    queryMap.set('user_id', userId)
    queryMap.set('date_from', dateFrom)
    queryMap.set('date_till', dateTill)
    queryMap.set('extended', true)
    var query = urlUtil.prepareQuery(queryMap)

    return new Promise((resolve, reject) => {
      api
        .get('v2/sales' + query)
        .then((res) => {
          const sales = this._prepareSales(res.data)
          resolve(sales)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareSales (sales) {
    var resultArray = []
    if (!sales) {
      return resultArray
    }

    sales.forEach((sale) => {
      resultArray = resultArray.concat(this._prepareSale(sale))
    })
    return resultArray
  },

  _prepareSale (sale) {
    if (!sale) {
      return null
    }

    if (sale.image) {
      sale.image = ImageRepository._prepareImage(sale.image)
    }
    return sale
  }
}
