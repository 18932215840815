export default {
  clone (object) {
    if (!object) {
      return null
    }

    return JSON.parse(JSON.stringify(object))
  },

  getDimension (maxDimension, mainDimension, secondDimension) {
    if (mainDimension > secondDimension) {
      return maxDimension
    }
    try {
      const weight = secondDimension / maxDimension
      const resultDimension = parseInt(mainDimension / weight)
      return resultDimension
    } catch (e) {
      console.error('Failed to calculate image dimension by values', maxDimension, mainDimension, secondDimension)
      return null
    }
  }
}
