import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'
import stringUtil from '@/js/utils/stringUtil'
import dateUtil from '@/js/utils/dateUtil'
import i18n from '@/plugins/i18n'

const MEDIA_BASE_URL = process.env.VUE_APP_MEDIA_BASE_URL

export default {
  getById (imageId) {
    return new Promise((resolve, reject) => {
      api.get('v2/images/' + imageId)
        .then(response => {
          var image = response.data
          resolve(this._prepareImage(image))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  find (keywords, imageType, orientation, author, collectionId, limit, offset, sort) {
    return new Promise((resolve, reject) => {
      var queryMap = new Map()
      queryMap.set('keyword', keywords)
      queryMap.set('image_type', imageType)
      queryMap.set('orientation', orientation)
      queryMap.set('author', author)
      queryMap.set('collection_id', collectionId)
      queryMap.set('limit', limit)
      queryMap.set('offset', offset)
      queryMap.set('sort', sort)

      var query = urlUtil.prepareQuery(queryMap)
      api.get('search' + query)
        .then(response => {
          var data = {}
          data.authors = response.data.authors
          data.total = response.data.total
          data.hits = response.data.hits
          data.numberOfOrgImages = response.data.numberOfOrgImages
          data.numberOfIllustration = response.data.numberOfIllustration
          data.numberOfPhotograph = response.data.numberOfPhotograph
          data.numberOfVideo = response.data.numberOfVideo
          data.images = this._prepareImages(response.data.images)
          data.additional_images = this._prepareImages(response.data.additional_images)

          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  searchV2 (searchQuery, type, orientation, author, limit, offset, sort) {
    return new Promise((resolve, reject) => {
      var queryMap = new Map()
      queryMap.set('query', searchQuery)
      queryMap.set('type', type)
      queryMap.set('orientation', orientation)
      queryMap.set('author', author)
      queryMap.set('limit', limit)
      queryMap.set('offset', offset)
      queryMap.set('sort', sort)

      var query = urlUtil.prepareQuery(queryMap)
      api.get('v2/images/search' + query)
        .then(response => {
          const images = this._prepareImages(response.data)
          resolve(images)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRandom (limit) {
    if (!limit) {
      limit = ''
    }

    return new Promise((resolve, reject) => {
      api
        .get('search-latest?limit=' + limit)
        .then((response) => {
          resolve(this._prepareImages(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareImages (imageArray) {
    var resultArray = []
    if (!imageArray) {
      return resultArray
    }

    imageArray.forEach((image) => {
      resultArray = resultArray.concat(this._prepareImage(image))
    })
    return resultArray
  },

  _prepareImage (image) {
    if (!image) {
      return null
    }

    const local = i18n.locale
    this._textToLocal(image, 'title', local)
    if (!image.title) {
      image.title = '...'
    }

    image.date_taken = dateUtil.getDateWithoutTimezone(image.date_taken)
    image.imageId = image.filename.substring(0, image.filename.lastIndexOf('.'))
    this._textToLocal(image, 'caption', local)
    this._textToLocal(image, 'restrictions', local)
    this._textToLocal(image, 'keywords', local)
    this._textToLocal(image, 'locations', local)

    let imageUrl = ''
    if (image.image_type === 'video') {
      imageUrl += '/video/'
    } else {
      imageUrl += '/image/'
    }
    imageUrl += encodeURIComponent(stringUtil.replaceSpaces(image.title.trim(), '-')).toLowerCase() + '/' + image.id
    image.url = imageUrl

    image.byline = this._getByline(image.author, image.owner)

    const height = image.height
    const width = image.width

    const resolutionMax = height * width
    let resolution = null
    if (resolutionMax >= 3840 * 2160) {
      resolution = '4K'
    } else if (resolutionMax >= 1920 * 1080) {
      resolution = 'HD'
    }
    image.resolution = resolution

    image.keywordsList = this._textToArray(image.keywords)
    image.keywordsTaxonomicList = this._textToArray(image.keywords_taxonomic)
    image.keywordsSvList = this._textToArray(image.keywords_sv)
    image.keywordsEnList = this._textToArray(image.keywords_en)
    image.locationsList = this._textToArray(image.locations)

    let srcImageBase = MEDIA_BASE_URL
    let srcVideoBase = MEDIA_BASE_URL
    if (image.image_type === 'video') {
      srcImageBase += '/video/cover/'
      srcVideoBase += '/video/preview/'

      image.src_video_400 = srcVideoBase + 't400/' + image.imageId + '.mp4'
      image.src_video_600 = srcVideoBase + 'w600/' + image.imageId + '.mp4'
      image.src_video_900 = srcVideoBase + 'w900/' + image.imageId + '.mp4'
      image.src_video_1300 = srcVideoBase + 'w1300/' + image.imageId + '.mp4'
    } else {
      srcImageBase += '/image/'
    }

    image.src_image_400 = srcImageBase + 't400/' + image.imageId + '.webp'
    image.src_image_600 = srcImageBase + 'w600/' + image.imageId + '.webp'
    image.src_image_900 = srcImageBase + 'w900/' + image.imageId + '.webp'
    image.src_image_1300 = srcImageBase + 'w1300/' + image.imageId + '.webp'

    return image
  },

  _textToArray (text1) {
    var tmpArray = []
    var tmpText = text1
    if (tmpText) {
      tmpArray = tmpText.trim()
        .split(',')
        .map(item => item.trim())
        .filter(String)
    }
    return tmpArray
  },
  _textToLocal (media, propName, lang) {
    if (!media || !propName || !lang) {
      return
    }

    if (!media[propName]) {
      media[propName] = media[propName + '_' + lang]
    }
  },

  _getByline (author, owner) {
    var parts = 0
    var byline = ''

    if (author && author.trim().length > 0) {
      byline = author
      parts++
    }
    if (owner && owner.trim().length > 0) {
      if (byline.length > 0) { byline += '/' }
      byline += owner
      parts++
    }
    if (parts < 2) {
      if (byline.length > 0) { byline += '/' }
      byline += 'azotelibrary.com'
    }
    return byline
  }
}
