import DashboardSalesRepository from '@/js/api/DashboardSalesRepository'
import DashboardCollectionRepository from '@/js/api/DashboardCollectionRepository'
import DashboardFeedbackRepository from '@/js/api/DashboardFeedbackRepository'

import objectUtil from '@/js/utils/objectUtil'

const getDefaultState = () => {
  return {
    userSales: [],
    collections: [],
    defaultCollectionId: null,
    feedbackList: [],
    imageFeedbackList: [],
    imageFeedbackStats: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUserSales (state, payload) {
      state.userSales = payload
    },
    setCollections (state, collections) {
      state.collections = objectUtil.clone(collections)
    },
    addCollection (state, collection) {
      state.collections.push(collection)
    },
    removeCollectionById (state, collectionId) {
      state.collections = state.collections.filter((collection) => collection.id !== collectionId)
    },
    setDefaultCollection (state, id) {
      state.defaultCollectionId = id
    },
    setFeedback (state, payload) {
      state.feedbackList = payload.feedback
      state.imageFeedbackList = payload.imageFeedback
    },
    setImageFeedbackStatistics (state, imageFeedbackStats) {
      state.imageFeedbackStats = imageFeedbackStats
    },
    removeImageFeedbackFromPendingList (state, imageFeedbackId) {
      if (!imageFeedbackId) {
        return
      }

      state.imageFeedbackList = state.imageFeedbackList.filter(item => item.id !== imageFeedbackId)
      const activeFeedbackIdsList = state.imageFeedbackList.map(imf => imf.feedback_id)
      const newFeedbackList = state.feedbackList.filter(item => activeFeedbackIdsList.includes(item.id))
      if (newFeedbackList.length !== state.feedbackList.length) {
        state.feedbackList = newFeedbackList
      }
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    getSales ({ commit }, payload) {
      const userId = this.getters['user/userId']
      return new Promise((resolve, reject) => {
        DashboardSalesRepository.getUserSales(userId)
          .then((res) => {
            commit('setUserSales', res)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    refreshCollections ({ commit }) {
      return new Promise((resolve, reject) => {
        DashboardCollectionRepository.findAll()
          .then((collections) => {
            commit('setCollections', collections)
            resolve(collections)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    joinCollection ({ commit }, code) {
      return new Promise((resolve, reject) => {
        DashboardCollectionRepository.joinCollection(code)
          .then((collection) => {
            commit('addCollection', collection)
            resolve(collection)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    leaveCollection ({ commit }, id) {
      return new Promise((resolve, reject) => {
        DashboardCollectionRepository.leaveCollection(id)
          .then((res) => {
            commit('removeCollectionById', id)
            resolve('ok')
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resolveFeedback ({ commit, getters }, imageId) {
      return new Promise((resolve, reject) => {
        if (!imageId) {
          reject(Error('No id specified!'))
        }

        const imageFeedback = getters.getImageFeedbackByImage(imageId)
        if (!imageFeedback || !imageFeedback.id) {
          reject(Error('No imageFeedback found by imageId: ' + imageId))
        }

        const imageFeedbackId = imageFeedback.id
        DashboardFeedbackRepository.resolveImageFeedback(imageFeedbackId)
          .then((imageFeedback) => {
            commit('removeImageFeedbackFromPendingList', imageFeedbackId)

            DashboardFeedbackRepository.getPendingImageFeedbackStatistics()
              .then((imageFeedbackStatistics) => {
                commit('setImageFeedbackStatistics', imageFeedbackStatistics)
              })
              .catch((error) => {
                reject(error)
              })

            resolve(imageFeedback)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    refreshImageFeedback ({ commit }) {
      return new Promise((resolve, reject) => {
        DashboardFeedbackRepository.getPendingImageFeedback()
          .then((imageFeedbackList) => {
            if (imageFeedbackList.length > 0) {
              DashboardFeedbackRepository.getPendingFeedback()
                .then((feedback) => {
                  commit('setFeedback', { feedback: feedback, imageFeedback: imageFeedbackList })
                })
                .catch((error) => {
                  reject(error)
                })

              DashboardFeedbackRepository.getPendingImageFeedbackStatistics()
                .then((imageFeedbackStatistics) => {
                  commit('setImageFeedbackStatistics', imageFeedbackStatistics)
                })
                .catch((error) => {
                  reject(error)
                })
            } else {
              commit('setFeedback', { feedback: [], imageFeedback: [] })
              commit('setImageFeedbackStatistics', [])
            }
            resolve('ok')
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    setDefaultCollection ({ commit }, id) {
      commit('setDefaultCollection', id)
    },

    reloadDefaultLists ({ commit, dispatch }) {
      dispatch('refreshCollections')
    }
  },

  getters: {
    userSales: (state) => state.userSales,
    collections: (state) => state.collections,
    getDefaultCollectionId: (state) => state.defaultCollectionId,
    imageFeedbackStats: (state) => state.imageFeedbackStats,
    feedbackList: (state) => state.feedbackList,
    getImageFeedbackByImage: (state) => (imageId) => {
      if (!imageId) {
        return null
      }

      imageId = parseInt(imageId)
      const imageFeedbackList = state.imageFeedbackList.filter(imageFeedback => imageFeedback.image_id === imageId)
      if (imageFeedbackList) {
        return imageFeedbackList[0]
      } else {
        return null
      }
    },
    getImageFeedbackByFeedback: (state) => (feedbackId) => {
      if (!feedbackId) {
        return null
      }

      feedbackId = parseInt(feedbackId)
      return state.imageFeedbackList.filter(imageFeedback => imageFeedback.feedback_id === feedbackId)
    },
    getFeedbackById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const feedbackList = state.feedbackList.filter(feedback => feedback.id === id)
      if (feedbackList) {
        return feedbackList[0]
      } else {
        return null
      }
    }
  }
}
