
export default {
  stringToArray (text) {
    if (!text) {
      return []
    }

    const result = text.split(',').map(function (item) {
      return item.trim()
    })
    return result
  },

  strip (text, maxLength) {
    if (!text) {
      return ''
    }

    if (text.length > maxLength) {
      if (maxLength <= 3) {
        text.substr(0, maxLength)
      } else {
        text = text.substr(0, maxLength - 2) + '..'
      }
    }
    return text
  },

  stripByWords (text, wordCount) {
    if (!text || !wordCount) {
      return text
    }

    let shortText = text
    const wordArr = text.split(' ')
    if (wordArr.length > wordCount) {
      shortText = ''
      for (let i = 0; i < wordCount; i++) {
        shortText += ' ' + wordArr[i]
      }
    }
    shortText = shortText.trim()
    return shortText
  },

  replaceSpaces (text, replaceString) {
    if (!text) {
      return text
    }

    return text.replace(/\s+/g, replaceString)
  },

  isEmpty (str) {
    return (!str || str.length === 0)
  }
}
