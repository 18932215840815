import i18n from '@/plugins/i18n'

export const SUPPORTED_LOCALES = [{
  code: 'sv',
  base: '',
  flag: 'sv',
  currency: 'SEK',
  name: 'Svenska',
  translations: '/locales/sv.json'
}, {
  code: 'en',
  base: '/en',
  flag: 'en',
  currency: 'EUR',
  name: 'English',
  translations: '/locales/en.json'
}]

export const LOCALES_REGEX = '(en|sv)'

export default {
  getLocaleByCode (localeCode) {
    if (!localeCode) {
      return this.getLocaleByCode('sv')
    }
    return SUPPORTED_LOCALES.find(loc => loc.code === localeCode)
  },

  getLocale () {
    return this.getLocaleByCode(i18n.locale)
  },

  isSupportedLocale (localeCode) {
    if (!localeCode) {
      return false
    }
    return localeCode.match(LOCALES_REGEX)
  }
}
