import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'

export default {

  getPendingFeedback () {
    const queryMap = new Map()
    queryMap.set('hide_resolved', true)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`v2/feedback${query}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPendingImageFeedback () {
    const queryMap = new Map()
    queryMap.set('hide_resolved', true)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`v2/image-feedback${query}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPendingImageFeedbackStatistics () {
    const queryMap = new Map()
    queryMap.set('hide_resolved', true)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`v2/image-feedback/statistics-by-status${query}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resolveImageFeedback (imageFeedbackId) {
    return new Promise((resolve, reject) => {
      api
        .post('v2/image-feedback/' + imageFeedbackId + '/resolve')
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

}
