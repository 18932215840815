import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'

export default {
  find (currencyVal) {
    return new Promise((resolve, reject) => {
      var queryMap = new Map()
      queryMap.set('currency', currencyVal)
      var query = urlUtil.prepareQuery(queryMap)
      api
        .get('v2/prices' + query)
        .then((response) => {
          const prices = response.data
          resolve(prices)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
